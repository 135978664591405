<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#">General Report</a>
                </li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Investment Report
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Investment Report</h4>
          </div>
        </div>
        <!-- content-header -->
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0">
          <select v-model="filter.size">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
          <select class="ml-2" v-model="filter.session_year">
            <option
              v-for="(year, index) in sessionYears"
              :key="index"
              :value="year"
            >
              {{ year }}/{{ parseInt(year.substring(year.length - 2)) + 1 }}
            </option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input
            type="text"
            v-model="filter.key"
            class="filter-input"
            placeholder="Search..."
          />
        </div>
      </div>
    </div>
    <div class="bg-theam-secondary table-search-head">
      <div>
        <span class="font-s tx-bold mr-2 tx-primary"
          >Opening Investment: NPR {{ dataSets.openingTotal }}</span
        >
        <span class="font-s tx-bold mr-2 tx-primary"
          >Closing Investment: NPR {{ dataSets.closingTotal }}</span
        >
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th class="wd-5p table-start-item">S.N</th>
              <th class="wd-75p">Account Name</th>
              <th class="wd-20p">Opening</th>
              <th class="wd-20p">Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(investment, index, key) in dataSets.data" :key="index">
              <th scope="row" class="table-start-item">{{ ++key }}</th>
              <th>{{ index }}</th>
              <td>NPR {{ investment.opening }}</td>
              <td>NPR {{ investment.closing }}</td>
            </tr>
          </tbody>
        </table>
        <Pagination />
      </div>
    </div>
  </div>
</template>
<script>
import { Search } from "../../../../mixins/search";
import Pagination from "../../components/pagination/pagination";
import { mapGetters } from "vuex";

export default {
  mixins: [Search],
  components: {
    Pagination,
  },
  data() {
    return {
      sessionYears: ["2018", "2019", "2020", "2021"],
      route: this.$route.path,
    };
  },
  computed: {
    ...mapGetters([
      "icons",
      "dataId",
      "dataLists",
      "dataLists9",
      "dataLists2",
      "removeList",
      "eventMessage",
      "modalId",
    ]),
  },
  methods: {
    filterReportType() {
      this.$router.push(this.route);
    },
    getData() {
      this.$store.commit(
        "getData",
        `api/investment/size/${this.filter.size}/year/${this.filter.session_year}`
      );
    },
  },
  beforeMount() {
    this.getData();
  },
};
</script>